import { ReactElement } from 'react'

export const IconJCB = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="25" height="25">
      <path
        fill="#1174ce"
        d="M9.5 9.6C4.7 9.6 1 12.7 1 18.1v15.4c.4 1.9 2.4 3.3 4.6 3.3 2.5 0 4.3-1.8 4.3-4V25h6.8v7.7c0 4.3-5.5 6.5-9.5 6.5-2.3 0-4.5-.7-6.1-1.9v17h10.8c3.8 0 8.2-3.6 8.2-8.2V9.6H9.5z"
        className="color1174CE svgShape"
      ></path>
      <path
        fill="#0f549d"
        d="M1 18.1v15.4c.4 1.9 2.4 3.3 4.6 3.3 2.5 0 4.3-1.8 4.3-4V25h6.8v7.7c0 4.3-5.5 6.5-9.5 6.5-2.3 0-4.5-.7-6.1-1.9v17h10.8c3.8 0 8.2-3.6 8.2-8.2V9.6"
        className="color0F549D svgShape"
      ></path>
      <path
        fill="#02375e"
        d="M16.6 25v7.7c0 4.3-5.5 6.5-9.5 6.5-2.3 0-4.5-.7-6.1-1.9v17h10.8c3.8 0 8.2-3.6 8.2-8.2"
        className="color02375E svgShape"
      ></path>
      <path
        fill="#146643"
        d="M47.3 36.6H52c1.2 0 2.3-1.1 2.3-2.3S53.2 32 52 32h-4.7v4.6z"
        className="color146643 svgShape"
      ></path>
      <path
        fill="#1bcc38"
        d="M52.6 9.6c-4.9 0-8.5 3.1-8.5 8.4v7h12.6c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3c2 0 3.6 1.4 3.6 3.2 0 1.8-1.5 3.2-3.6 3.2H44v16.3h10.8c3.8 0 8.2-3.6 8.2-8.2V9.6H52.6z"
        className="color1BCC38 svgShape"
      ></path>
      <path
        fill="#329947"
        d="M44 18.1V25h12.6c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3c2 0 3.6 1.4 3.6 3.2 0 1.8-1.5 3.2-3.6 3.2H44v16.3h10.8c3.8 0 8.2-3.6 8.2-8.2V9.6"
        className="color329947 svgShape"
      ></path>
      <path
        fill="#146643"
        d="M59.9 28.3c0 1.8-1.5 3.3-3.3 3.3 2 0 3.6 1.4 3.6 3.2 0 1.8-1.5 3.2-3.6 3.2H44v16.3h10.8c3.8 0 8.2-3.6 8.2-8.2M54.2 28.7c0-1.2-1.1-2.3-2.3-2.3h-4.6v4.5H52c1.2 0 2.2-1 2.2-2.2z"
        className="color146643 svgShape"
      ></path>
      <path
        fill="#e20e37"
        d="M30.9 9.6c-4.9 0-8.5 3.1-8.5 8.4v8.6c1.2-1 2.7-1.7 4.3-1.7h11.4v2.4c-2.7-.6-5.3-.9-6.6-.9-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1c1.3 0 3.9-.2 6.6-.8V38H26.7c-1.6 0-3.2-.6-4.3-1.7v18h10.8c3.8 0 8.2-3.6 8.2-8.2V9.6H30.9z"
        className="colorE20E37 svgShape"
      ></path>
      <path
        fill="#b41f36"
        d="M22.4 18.1v8.6c1.2-1 2.7-1.7 4.3-1.7h11.4v2.4c-2.7-.6-5.3-.9-6.6-.9-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1c1.3 0 3.9-.2 6.6-.8V38H26.7c-1.6 0-3.2-.6-4.3-1.7v18h10.8c3.8 0 8.2-3.6 8.2-8.2V9.6"
        className="colorB41F36 svgShape"
      ></path>
      <path
        fill="#720a1e"
        d="M38.1 25v2.4c-2.7-.6-5.3-.9-6.6-.9-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1c1.3 0 3.9-.2 6.6-.8V38H26.7c-1.6 0-3.2-.6-4.3-1.7v18h10.8c3.8 0 8.2-3.6 8.2-8.2"
        className="color720A1E svgShape"
      ></path>
    </svg>
  )
}
