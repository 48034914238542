import { Fragment, ReactElement, useEffect } from 'react'

import { HOME_LINK } from '../../constants/common'

export const HomePage = (): ReactElement => {
  useEffect(() => {
    window.location.replace(HOME_LINK)
  }, [])

  return <Fragment />
}
