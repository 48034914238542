import { Box, Button, Flex, Modal, Text, Image, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { FC } from 'react'

type ErrorModalProps = {
  isOpen: boolean
  onClose: () => void
  error?: string
}

export const ErrorModal: FC<ErrorModalProps> = ({ isOpen, onClose, error }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth={492}>
        <Flex px={5} py={8} direction={'column'} alignItems={'center'} justifyContent={'center'}>
          <Box mt={5} mb={'30px'}>
            <Image src={`${process.env.REACT_APP_DOMAIN}/error-credit.png`} w={'150px'} />
          </Box>
          <Text fontSize={30} fontWeight={600} mb={3} color={'#595959'}>
            ERROR
          </Text>
          <Text fontSize={18} fontWeight={400}>
            {error || 'Please select another payment method.'}
          </Text>
          <Button
            fontWeight={500}
            mt={5}
            h={'41px'}
            colorScheme={'red'}
            fontSize={17}
            onClick={onClose}
            _hover={{ backgroundImage: 'linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1))' }}
            _active={{ backgroundImage: 'linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1))' }}
          >
            TRY AGAIN
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
