import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

export const IconBack: FC<IconProps> = (props) => {
  return (
    <Icon
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      className="cursor-pointer"
      height="5"
      width="5"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
    </Icon>
  )
}
