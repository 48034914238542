import { Flex, useMediaQuery } from '@chakra-ui/react'
import { ReactElement } from 'react'

import { Info } from '../modules/Info'
import { Checkout } from '../modules/Checkout'

export const CheckoutPage = (): ReactElement => {
  const [isLargerThan1130] = useMediaQuery('(min-width: 1130px)')

  return (
    <Flex
      direction={isLargerThan1130 ? 'row' : 'column'}
      justifyContent={'center'}
      alignItems={isLargerThan1130 ? 'flex-start' : 'center'}
      h={'full'}
      bg={'rgb(241 245 249)'}
    >
      <Info />
      <Checkout />
    </Flex>
  )
}
