import { FC, ReactNode } from 'react'
import { Flex, Text } from '@chakra-ui/react'

type InputFormProps = {
  label: string
  children: ReactNode
}

export const InputForm: FC<InputFormProps> = ({ label, children }) => {
  return (
    <Flex direction={'column'} flex={1} w={'full'} alignItems={'flex-start'} justifyContent={'center'}>
      <Text>{label}</Text>
      {children}
    </Flex>
  )
}
