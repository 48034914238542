import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { FC } from 'react'

type InputPhoneProps = PhoneInputProps & {
  isInvalid?: boolean
}

export const InputPhone: FC<InputPhoneProps> = ({ isInvalid, ...props }) => {
  return (
    <PhoneInput
      country={'us'}
      inputStyle={{
        width: '100%',
        paddingLeft: 40,
        borderColor: isInvalid ? '#E53E3E' : '#e6e6e6',
        boxShadow: isInvalid ? '0 0 0 1px #E53E3E' : undefined,
      }}
      dropdownStyle={{ top: 'auto', bottom: '100%' }}
      countryCodeEditable={false}
      buttonStyle={{
        background: 'white',
        borderRightWidth: 0,
        borderColor: isInvalid ? '#E53E3E' : '#e6e6e6',
      }}
      {...props}
    />
  )
}
