import { Box, Divider, Flex, Image, Link, Text, useMediaQuery } from '@chakra-ui/react'
import { ReactElement } from 'react'
import Countdown from 'react-countdown'

import { IconBack } from '../elements/IconBack'
import { HOME_LINK } from '../../constants/common'

export const Info = (): ReactElement => {
  const [isLargerThan1720] = useMediaQuery('(min-width: 1720px)')
  const [isLargerThan462] = useMediaQuery('(min-width: 462px)')

  return (
    <Flex direction={'row'} flex={1} alignItems={'flex-start'} h={'full'}>
      <Flex flex={isLargerThan1720 ? 1 : undefined} />
      <Flex flex={1} direction={'column'} justifyContent={'center'} gap={'44px'} alignItems={'flex-end'} px={12} py={8}>
        <Flex
          direction={isLargerThan462 ? 'row' : 'column'}
          w={'full'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Image src={`${process.env.REACT_APP_DOMAIN}/logo.png`} h={20} />
          <Image src={'https://cdn.bettamax.com/dev/trust_store.svg'} maxW={'full'} />
        </Flex>

        <Flex direction={'column'} justifyContent={'center'} alignItems={'flex-start'} flex={1} w={'full'}>
          <Flex direction={'column'} w={'full'}>
            <Flex
              direction={'row'}
              gap={'.5rem'}
              flex={1}
              alignItems={'center'}
              justifyContent={'flex-start'}
              w={'full'}
            >
              <Link href={HOME_LINK}>
                <IconBack cursor={'pointer'} />
              </Link>
              <Text fontSize={20} fontWeight={700} lineHeight={'28px'}>
                Order Summary
              </Text>
            </Flex>

            <Flex py={'17px'} gap={'1.25rem'} direction={'row'}>
              <Image src={`${process.env.REACT_APP_DOMAIN}/products/camera.jpg`} w={'150px'} />

              <Flex direction={'column'} justifyContent={'space-between'} flex={1}>
                <Text fontWeight={'bold'} fontSize={'1rem'} lineHeight={'1.5rem'} whiteSpace={'pre-line'}>
                  {'BUY 1 GET 1\nAnti-theft camera'}
                </Text>
                <Flex
                  direction={isLargerThan462 ? 'row' : 'column'}
                  alignItems={isLargerThan462 ? 'center' : 'flex-start'}
                  justifyContent={'space-between'}
                  w={'full'}
                >
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Quantity:
                    </Text>
                    <Text as={'span'}> 1</Text>
                  </Box>

                  <Flex direction={'row'} gap={'.5rem'} alignItems={'center'}>
                    <Text
                      color={'rgb(119 119 119)'}
                      textDecoration={'line-through'}
                      fontSize={'.875rem'}
                      lineHeight={'1.25rem'}
                    >
                      $45.00
                    </Text>
                    <Text color={'rgb(27 27 27)'} fontSize={'1.125rem'} lineHeight={'1.75rem'}>
                      $12.5
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Divider borderColor={'rgb(236 239 244)'} />

          <Flex pt={'.75rem'} direction={'column'} gap={'.5rem'} flex={1} w={'full'}>
            <Flex flex={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Text>Subtotal</Text>
              <Text>$12.5</Text>
            </Flex>

            <Flex flex={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Text>Shipping</Text>
              <Text>$04.00</Text>
            </Flex>

            <Flex
              flex={1}
              direction={'row'}
              fontSize={16}
              fontWeight={700}
              lineHeight={'24px'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Text>Total</Text>
              <Text>$16.50</Text>
            </Flex>
          </Flex>

          <Flex
            bg={'white'}
            p={8}
            direction={'row'}
            w={'full'}
            flex={1}
            alignItems={'center'}
            justifyContent={'center'}
            gap={'1.25rem'}
            mt={10}
          >
            <Image w={'64px'} src={'https://cdn.bettamax.com/dev/2023-08-28/coupon.svg'} />
            <Flex direction={'column'} flex={'1 1 auto'}>
              <Text fontSize={'1.125rem'} lineHeight={'1.75rem'}>
                Limited Stock!
              </Text>
              <Text lineHeight={'1.25rem'} fontSize={'.875rem'} whiteSpace={'pre-line'}>
                {'No worries, we have reserved your order.'}
              </Text>
              <Countdown
                date={new Date('2024-12-09').getTime()}
                renderer={({ minutes, seconds }): ReactElement => (
                  <Text lineHeight={'1.25rem'} fontSize={'.875rem'}>
                    Your order is reserved for{' '}
                    <Text as={'span'} color={'#c3392f'} fontWeight={600}>
                      {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                    </Text>{' '}
                    minutes
                  </Text>
                )}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
