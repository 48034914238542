import { ReactElement } from 'react'

export const IconVisa = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="40" height="40">
      <g transform="translate(81.668 -382.533)">
        <rect
          width="63.214"
          height="40.714"
          x="-61.758"
          y="413.183"
          fill="#f9f9f9"
          fillRule="evenodd"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".8"
          rx="3.599"
          ry="3.599"
          className="colorf9f9f9 svgShape colorStroke000 svgStroke"
        ></rect>
        <path
          fill="#005bac"
          fillRule="evenodd"
          d="m211.328 184.445-23.465 144.208h37.542l23.468-144.208h-37.545zm-55.052 0-35.794 99.185-4.234-21.358.003.007-.933-4.787c-4.332-9.336-14.365-27.08-33.31-42.223a150.19 150.19 0 0 0-16.705-11.559l32.531 124.943h39.116l59.733-144.208h-40.407zm146.521 40.035c0-16.304 36.563-14.209 52.629-5.356l5.357-30.972s-16.534-6.288-33.768-6.288c-18.632 0-62.875 8.148-62.875 47.739 0 37.26 51.928 37.723 51.928 57.285 0 19.562-46.574 16.066-61.944 3.726l-5.586 32.373s16.763 8.148 42.382 8.148c25.616 0 64.272-13.271 64.272-49.37 0-37.493-52.395-40.985-52.395-57.285zm153.2-40.035h-30.185c-13.938 0-17.332 10.747-17.332 10.747l-55.988 133.461h39.131l7.828-21.419h47.728l4.403 21.419h34.472l-30.057-144.208zm-45.727 93.196 19.728-53.966 11.098 53.966H410.27z"
          transform="translate(-58.272 406.232) scale(.10672)"
          className="color005bac svgShape"
        ></path>
        <path
          fill="#f6ac1d"
          fillRule="evenodd"
          d="M104.132 198.022s-1.554-13.015-18.144-13.015H25.715l-.706 2.446s28.972 5.906 56.767 28.033c26.562 21.148 35.227 47.51 35.227 47.51l-12.871-64.974z"
          transform="translate(-58.272 406.232) scale(.10672)"
          className="colorf6ac1d svgShape"
        ></path>
      </g>
    </svg>
  )
}
