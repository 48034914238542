import { Input, InputProps } from '@chakra-ui/react'
import { FC } from 'react'

export const StyledInput: FC<InputProps> = (props) => {
  return (
    <Input
      {...props}
      _focus={{
        borderColor: 'hsla(210, 96%, 45%, 50%)',
        outline: 0,
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%) , 0 1px 1px 0 rgba(0, 0, 0, 0.08)',
      }}
    />
  )
}
